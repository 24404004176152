@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #F5F7FE;
}

.placeholder {
  height: 90px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 9;
}

.header-wrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

header .logo {
  font-size: 32px;
  font-weight: 600;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
}

header .profile-wrapper {
  position: relative;
}

header .profile-wrapper .profile-name {
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  border-radius: 1000px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  transition: all 500ms ease;
  cursor: pointer;
  border: 1px solid transparent;
}

header .profile-wrapper .profile-name:hover {
  border: 1px solid #164F4C;
  background: white;
  color: #164F4C;
}

header .profile-wrapper ul {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 100%;
  box-shadow: 0px 10px 15px -3px #c1e4e3;
  background-color: white;
  list-style-type: none;
  border-radius: 6px;
  min-width: 200px;
  max-width: 200px;
  margin-top: 10px;
}

header .profile-wrapper ul li {
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 400ms ease;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

header .profile-wrapper ul .user-name {
  color: #164F4C;
  font-weight: 600;
}

header .profile-wrapper ul .log-out {
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 16px;
  margin-bottom: 8px;
  transition: all 500ms ease;
  padding: 6px;
  border: 1px solid transparent;
}

header .profile-wrapper ul .log-out:hover {
  background: white;
  border: 1px solid #164F4C;
  color: #164F4C;
}

.login {
  background: #164F4C;
}

.login-container {
  width: 60%;
  margin: auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.app-label {
  margin-right: auto;
}

.app-label h1 {
  font-size: 60px;
  color: white;
  font-weight: 600;
}

.wrapper {
  margin-left: auto;
  position: relative;
  width: 100%;
  background: #fff;
  padding: 34px;
  border-radius: 6px;
  box-shadow: 0px 10px 15px -3px #95c6c4;
}

.wrapper h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

.wrapper h2::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 28px;
  border-radius: 12px;
  background: #164F4C;
}

.wrapper .form {
  margin-top: 30px;
}

.wrapper .form .input-box {
  height: 52px;
  margin: 18px 0;
}

.form .input-box input {
  width: 100%;
  outline: none;
  padding: 12px 15px;
  font-size: 17px;
  font-weight: 400;
  color: #333;
  border: 1.5px solid #C7BEBE;
  border-bottom-width: 2.5px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.input-box input:focus {
  border-color: #164F4C;
}

.form .policy {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form .password-input {
  position: relative;
}

.form .password-input .password__show {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: white;
  padding-left: 4px;
  font-size: 14px;
  min-width: 50px;
  text-align: right;
}

.form h3 {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.input-box button {
  color: #fff;
  letter-spacing: 1px;
  border: none;
  background: #164F4C;
  cursor: pointer;
  padding: 12px 15px;
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  border-radius: 6px;
}

.input-box button:disabled {
  opacity: 0.5;
}

.input-box button:hover {
  background: #164F4C;
}

.form .text h3 {
  color: #333;
  width: 100%;
  text-align: center;
}

.form .text h3 a {
  color: #164F4C;
  text-decoration: none;
}

.form .text h3 a:hover {
  text-decoration: underline;
}

.error {
  background-color: #f5c3c3;
  color: #ff0000;
  padding: 12px 20px;
  text-align: center;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.navbar {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: flex-end;
}


.page-title {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title .count {
  font-size: 16px;
}

.navbar .button-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
  align-items: center;
}

.header-wrapper .button-wrapper button,
.navbar .button-wrapper button {
  padding: 8px 12px;
  text-transform: capitalize;
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  min-width: 100px;
  transition: all 300ms ease-in-out;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border: 1px solid transparent;
}

.header-wrapper .button-wrapper button {
  justify-content: center;
}

.navbar button.active {
  background: white !important;
  color: #164F4C !important;
  border: 1px solid #164F4C !important;
}

.navbar .button-wrapper button:hover {
  background: white;
  color: #164F4C;
  border: 1px solid #164F4C;
}

.page-wrapper .navbar .custom-datepicker {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  display: grid;
  margin: auto;
}

.page-wrapper .navbar .button-container.input-container .custom-input,
.page-wrapper .navbar .button-container.reservations-topbar-container .custom-input {
  border: 1px solid #164F4C;
  border-radius: 6px;
  background-color: white;
  padding: 12px 16px;
  width: 100%;
}

.page-wrapper .navbar .button-container.reservations-topbar-container .custom-input {
  padding: 8px 12px;
  min-width: 200px;
}

.page-wrapper .navbar .button-container.input-container .custom-input:focus-visible {
  outline: none;
}

.page-wrapper .navbar .button-container.input-container .custom-input:focus {
  border: 1px solid #707070;
}


.page-wrapper .navbar .custom-datepicker-col-3 {
  grid-template-columns: auto 1fr 1fr;
}

.page-wrapper .navbar .custom-datepicker-col-2 {
  grid-template-columns: 1fr 1fr;
}

.page-wrapper .navbar .custom-datepicker-reservations {
  grid-template-columns: auto 1fr 1fr 1fr 1fr;
}

.navbar .checkout-container {
  display: flex;
  align-items: flex-end;
  gap: 8px
}

.navbar .pagination-limit {
  padding-bottom: 4px;
}

.navbar .button-wrapper-heading {
  font-size: 14px;
  color: #164F4C;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.navbar .button-wrapper-heading span {
  display: flex;
}

.page-wrapper .button-container {
  border: 1px solid #164F4C;
  padding: 4px;
  border-radius: 6px;
}

.page-wrapper .button-container.input-container {
  border: none;
  padding: 0;
  border-radius: 0;
}


.page-wrapper .navbar .custom-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.page-wrapper .navbar .custom-datepicker .select-box:not(button) {
  min-width: 150px;
}

.page-wrapper .navbar .custom-datepicker .select-box {
  padding: 8px 12px;
  text-transform: capitalize;
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  min-width: 100px;
  transition: all 300ms ease-in-out;
  border: 1px solid transparent;
}

.page-wrapper .navbar .custom-datepicker .select-box:hover {
  background: white;
  border: 1px solid #164F4C;
  color: #164F4C;
}

.page-wrapper .navbar .custom-datepicker .custom-datepicker-box {
  width: 80%;
}

.page-wrapper {
  width: 100%;
  background-color: #F5F7FE;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: calc(100vh - 90px);
}

.table {
  display: table;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.table .t-row.t-head {
  font-weight: 600;
  text-transform: uppercase;
  color: #707070;
  background-color: #F5F7FE;
}

.table .t-row.t-head .t-item {
  display: flex;
  gap: 4px;
  align-items: center;
}

.table .t-row.t-head .t-item.active {
  color: #2a53b8;
}

.table .t-row.t-head .t-item span {
  display: flex;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.table .t-row.t-head .t-item span:hover {
  color: #2a53b8;
}

.table .t-row {
  display: grid;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 8px;
  transition: all 400ms ease-in-out;
  align-items: center;
}

.table .t-row.grid-7 {
  grid-template-columns: 1.5fr 0.5fr 2fr 0.75fr 0.75fr 1fr 1fr;
}

.table .t-row.grid-6 {
  grid-template-columns: 1.5fr 0.5fr 2fr 0.75fr 0.75fr 1fr;
}

.table .t-row.available-properties-row {
  grid-template-columns: 1.5fr 0.5fr 0.75fr 0.75fr 0.75fr 0.75fr;
}

.table .t-row .t-item {
  padding: 16px 8px;
}

.table .t-row .t-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.table .t-row .t-item.date-item {
  justify-content: center;
}

.table .t-body .t-row .t-item.first-item-checkin {
  color: #164F4C;
}

.table .t-row .t-item .t-item-label {
  display: none;
}

.badge {
  background-color: green;
  color: white;
  padding: 4px;
  border-radius: 6px;
  display: inline;
  font-size: 12px;
}

.d-none {
  display: none !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.pagination .pagination-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
}

.pagination .pagination-wrapper span {
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination .pagination-wrapper span.active {
  background-color: #164F4C;
  color: white;
}

.pagination-limit {
  width: fit-content;
  text-align: right;
}

.pagination-limit select {
  margin-left: auto;
  padding: 7px;
  border: 1px solid #C7BEBE;
  color: #707070;
  font-size: 14px;
  border-radius: 8px;
  min-width: 100px;
  font-size: 12px;
}

.loading {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data svg {
  max-height: 500px;
}

.page-wrapper .react-datepicker__day--keyboard-selected,
.page-wrapper .react-datepicker__month-text--keyboard-selected,
.page-wrapper .react-datepicker__quarter-text--keyboard-selected,
.page-wrapper .react-datepicker__year-text--keyboard-selected {
  background-color: #95c6c4;
}

.page-wrapper .react-datepicker__close-icon::after {
  border: 1px solid white;
  background-color: #164F4C;
}

.react-datepicker__header {
  background-color: #F5F7FE;
}

.react-datepicker__month-container select {
  height: auto;
  padding: 4px;
}

.react-datepicker__triangle {
  display: none
}

.tabs {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tabs button {
  background: white;
  border: 1px solid #164F4C;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #164F4C;
  transition: all 300ms ease;
}


.tabs button:hover {
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  color: white;
}

.tabs button.active {
  background: #164f4c;
  background: linear-gradient(98deg, #164f4c 0%, #2b6966 19%, #3f827e 43%, #559c98 65%, #62aca8 86%, #6fbcb8 100%);
  color: white;
}

.tabs button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tabs button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .mob-w-100 {
    width: 100%;
  }

  .page-title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  .page-title .count {
    font-size: 12px;
  }

  .login-container {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .app-label {
    display: none;
  }

  .navbar {
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .page-wrapper .navbar .custom-datepicker {
    width: 100%;
    gap: 8px;
  }

  .page-wrapper .navbar .button-container.reservations-topbar-container .custom-input {
    min-width: auto;
  }

  .page-wrapper .navbar .custom-datepicker-reservations {
    grid-template-columns: 1fr 1fr;
  }

  .navbar .button-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .page-wrapper .navbar .custom-datepicker .select-box,
  .navbar .button-wrapper button {
    font-size: 12px;
    padding: 8px 8px;
  }

  .page-wrapper .navbar .custom-datepicker-col-3,
  .page-wrapper .navbar .custom-datepicker-col-2 {
    grid-template-columns: 1fr 1fr;
  }

  .page-wrapper .navbar .custom-datepicker .select-box {
    width: 100%;
  }

  .navbar .checkout-container {
    flex-direction: column;
    width: 100%;
  }

  .navbar .checkout-container~div {
    width: 100%;
  }

  .navbar .button-wrapper-heading {
    padding-bottom: 4px;
  }

  .navbar .button-wrapper-heading span svg {
    height: 14px;
    width: 14px;
  }

  .table .t-row.t-head {
    display: none;
  }

  .table .t-row {
    margin-bottom: 12px;
    grid-template-columns: 1fr !important;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .table .t-row .t-item {
    padding: 4px;
  }

  .header-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  header .logo {
    font-size: 24px;
  }

  header .profile-wrapper .profile-name {
    height: 32px;
    width: 32px;
    font-size: 16px;
  }

  .placeholder {
    height: 52.8px;
  }

  .table .t-body .t-row .t-item {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }

  .table .t-row .t-item span:not(.t-item-label) {
    text-align: right;
  }

  .table .t-row .t-item .t-item-label {
    display: inline-block;
    padding-right: 4px;
    font-weight: 500;
    color: #707070;
  }

  .table .t-body .t-row .t-item.status-desk {
    display: none;
  }

  .d-none {
    display: inline-block !important;
  }

  .mob-d-none {
    display: none !important;
  }

  .table .t-body .t-row .t-item.g-name {
    grid-template-columns: 3fr 1fr;
    color: #164F4C;
    font-weight: 500;
    font-size: 16px;
    text-align: left !important;
  }

  .table .t-body .t-row .t-item.g-name span:first-child {
    text-align: left;
  }

  .badge {
    font-size: 10px;
  }

  .pagination-limit {
    width: fit-content;
  }

  .pagination .pagination-wrapper {
    width: 80%;
  }

  .pagination-limit select {
    min-width: auto;
  }

  .tabs button {
    padding: 8px;
    font-size: 12px;
  }
}